import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import Layout from '../components/layout'
import '../styles/career.css'

const career = () => {
    return (
        <Layout>
            <div className="career_background p_background_img">
                <div className="cover-img-overlay">
                    <Container>
                        <Row>
                        <Col xl={12} lg={12} md={12} >
                            {/* <div className="p_vertical_line tr"></div> */}
                                <h1 className="p_head_blog career-intro-head" style={{textAlign:'center'}}>Page Under Development</h1>
                                <h4 style={{textAlign:'center',color:'white'}}>Will be available shortly</h4>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            {/* <div class="p_btm_svg" style={{marginBottom:'50vh'}}></div> */}
                {/* <Container>
                    <p className="career_txt">Currently we don’t have any openings. However, you may submit your resume here so that we can find you when in need.</p>

                        
                            <Row>
                                <Col xl={1} lg={1}></Col>
                                    <Col xl={10} lg={10}>
                                        <div className="careerForm">
                                            
                                            <Form id="fs-frm" name="simple-contact-form" accept-charset="utf-8" action="https://formspree.io/xbjplypy" method="post">
                                                <fieldset id="fs-frm-inputs">
                                                
                                                    <Form.Group controlId="exampleForm.ControlInput1">
                                                    <Row>
                                                        <Col xl={4}>
                                                        <Form.Label>Full name</Form.Label>
                                                        </Col>
                                                        <Col xl={8}>
                                                        <Form.Control className="inputBg" type="Text" placeholder="Full Name" name="name" id="full-name" required/>
                                                        </Col>
                                                    </Row>  
                                                    </Form.Group>

                                                    <Form.Group controlId="exampleForm.ControlInput2">
                                                        <Row>
                                                            <Col xl={4}>
                                                            <Form.Label>Qualification</Form.Label>
                                                            </Col>
                                                            <Col xl={8}>
                                                            <Form.Control className="inputBg" type="text" placeholder="Your educational Qualification?" name="qualification" id="qualification" required/>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                    
                                                    <Form.Group controlId="exampleForm.ControlInput3">
                                                        <Row>
                                                            <Col xl={4}>
                                                            <Form.Label>Current Employer (if any)</Form.Label>
                                                            </Col>
                                                            <Col xl={8}>
                                                            <Form.Control className="inputBg" type="text" placeholder="Current Employer (if any)" name="currentemployer" id="current-employer" />
                                                            </Col>
                                                        </Row>          
                                                      </Form.Group>

                                                      <Form.Group controlId="exampleForm.ControlInput4">
                                                        <Row>
                                                            <Col xl={4}>
                                                            <Form.Label>Profession</Form.Label>
                                                            </Col>
                                                            <Col xl={8}>
                                                            <Form.Control className="inputBg" type="text" placeholder="Your Profession" name="Profession" id="Profession" required/>
                                                            </Col>
                                                        </Row>          
                                                      </Form.Group>  

                                                    
                                                         

                                                    <input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission"></input>
                                                    <Button variant="primary" type="submit" className="crr_button mx-auto d-block">
                                                        Submit
                                                    </Button>
                                                
                                                </fieldset>
                                            </Form>     
                                        </div>
                                    </Col>
                                <Col xl={1} lg={1}></Col>

                            </Row>
                        
                </Container> */}
        </Layout>
    )
} 

export default career